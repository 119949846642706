html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--default-font-family);
  background-color: #fff;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus {
  outline: 0;
  box-shadow: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul,
li {
  padding: 0;
  margin: 0;
  display: block;
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: inherit;
}

.react-datepicker__header {
  background-color: #0f0f14 !important;
}

.react-datepicker__current-month {
  color: #ae4294 !important;
}

.react-datepicker {
  border: 1px solid #0f0f14 !important;
  height: 50px !important;
}

.react-datepicker__day-name {
  color: #ffffff !important;
}

.react-datepicker__day--selected {
  background-color: #ae4294 !important;
  border-radius: 50% !important;
  color: #ffffff !important;
}

.react-datepicker {
  background-color: #0f0f14 !important;
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
}

.react-datepicker__month-container {
  background-color: #0f0f14 !important;
}

.react-datepicker__day {
  color: #7d829c !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: #ae4294 !important;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected {
  color: #ffffff !important;
}

.react-datepicker__day.react-datepicker__day--selected {
  color: #ffffff !important;
}

.react-datepicker {
  font-family: "Comfortaa", sans-serif !important;
}

.react-datepicker__day--outside-month {
  color: transparent !important;
  pointer-events: none !important;
}

/* .react-datepicker__input-container{
  width:700px !important

}  */

.react-datepicker-time__header {
  color: #ffffff !important;
}

.react-datepicker__time-container .react-datepicker__time {
  background: #0f0f14 !important;
  color: #fff;
}

.react-datepicker__time-list-item--selected {
  background: #000 !important;
  color: #0f0f14 !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background: #fff !important;
  color: #0f0f14 !important;
}

.react-datepicker__time-list-item:hover {
  background: #000 !important;
  color: #fff !important;
}

/* .ytp-chrome-top {
    display: none !important;
} */
