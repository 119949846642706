@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --app-height: 100%;
}

* {
  scroll-behavior: smooth;
  /* outline: thin solid lightcoral; */
}

@media not all and (hover: hover) {
  .custom-height {
    height: var(--app-height);
  }
}

html,
body {
  margin: 0 !important;
  padding: 0 !important;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: none;
  font-family: var(--default-font-family);
  font-family: 'DM Sans', sans-serif;
  -webkit-tap-highlight-color: transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: #f5f6fa;
  overflow-x: hidden;
}

* {
  scrollbar-color: #cf021a #fff;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

a {
  -webkit-tap-highlight-color: transparent;
}

textarea {
  resize: none;
}

/* input:focus,
textarea:focus {
  outline: 0;
  border: 0;
} */

.react-datepicker__input-container>input:focus {
  border: 1px solid #7f82ba;
}

.react-datepicker__input-container>input {
  height: 50px !important;
  /* margin-top: -0.2rem; */
  width: 100% !important;
}

.react-datepicker__input-container {
  background-color: white !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  line-height: 120.4%;
  letter-spacing: -0.02em;
}

.no-scrollbar {
  scrollbar-width: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

input,
textarea,
select {
  border: 1px solid #7f82ba;
  width: 100%;
  padding: 8px 10px;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 400;
  background-color: transparent;
  transition: all 0.3s ease;
}

::-ms-reveal {
  display: none;
}

select {
  appearance: none;
  cursor: pointer;
  font-weight: 500;
  border-radius: 0.25em;
}

.common-input {
  border: 1px solid #7f82ba;
  width: 100%;
  padding: 8px 10px;
  padding-left: 5px;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  background-color: transparent;
  transition: all 0.3s ease;
  background-color: white;
}

.input-white {
  background-color: white;

}

.pr-border {
  border: 1px solid #3f3f47;
}

.common-input-live {
  border-radius: 0px;
  padding-right: 50px;
  padding-left: 0px;
  border: none;
  background-color: transparent;
}


.common-input-live-reply {
  border-radius: 0px;
  padding-right: 50px;
  padding-left: 0px;
  border: none;
  background-color: #f5f5f5;
}

.border-round {
  border: 1px solid #243669;
  border-radius: 50px;
}

.common-input-reply {
  border: none;

}

.common-input:focus {
  border: 1px solid #104553;
}

.common-input-live:focus, .common-input-live-reply:focus {
  border: none;
}

.input-error {
  border: 1px solid #cf021a;
}

.input-success {
  border: 1px solid #1dbf73;
}

.input-success:focus {
  border: 1px solid #1dbf73;
}

.input-error:focus {
  border: 1px solid #cf021a;
}

.hr-color {
  background: rgba(98, 100, 106, 0.25);
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  min-width: 60px;
  height: 34px;
  outline: none;
}

.switch input {
  position: absolute;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #25cc89;
}

input:focus+.slider {
  box-shadow: 0 0 1px #25cc89;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.overview-styles {
  margin: 0 120px;
  padding: 24px;
}

@media screen and (max-width: 768px) {
  .overview-styles {
    margin: 0px;
    padding: 5px;
    padding-left: 3px;
    padding-right: 0px;
  }
}

.layover {
  background-color: #000000;
  opacity: 0.4;
}

.animateLinkAsButton {
  transition: all ease-in 0.3s;
  transform: perspective(100px) translateZ(0px);
}

.animateLinkAsButton:hover {
  transform: perspective(100px) translateZ(5px);
}

.sk-double-bounce {
  width: 4em;
  height: 4em;
  position: relative;
  margin: auto;
}

.sk-double-bounce .sk-child {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #243669;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-double-bounce 2s infinite ease-in-out;
  animation: sk-double-bounce 2s infinite ease-in-out;
}

.sk-double-bounce .sk-double-bounce-2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-double-bounce {

  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

@keyframes sk-double-bounce {

  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

.live-color {
  color: #fd4438 !important;
  background-color: rgba(253, 68, 56, 0.2);
}

.upcoming-color {
  color: #449fad !important;
  background-color: rgba(68, 159, 173, 0.2);
}

.aired-color {
  color: #e7b10e !important;
  background-color: rgba(231, 177, 14, 0.2);
}

.select__placeholde {
  color: #104553 !important;
}

.cancelled-color {
  color: #104553 !important;
  background-color: rgba(128, 128, 128, 0.2);
}



.vjs-poster {
  background-size: cover !important;
  background-position: center !important;
  height: 100% !important;
  width: 100% !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 1 !important;
  object-fit: cover !important;
}

video {
  background-position: center !important;
  background-size: cover !important;
  height: 100% !important;
  width: 100% !important;
  object-fit: cover !important;
}

@media (min-width: 1536px) {
  .container {
    max-width: unset;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: unset;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: unset;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: unset;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: unset;
  }
}

@media (min-width: 281px) {
  .container {
    max-width: unset;
  }
}

.vjs-fluid {
  padding-top: 0 !important;
}

@media screen and (max-width: 768px) {
  .vjs-fluid {
    height: 100% !important;
  }
}

.vjs_video_3-dimensions {
  width: 100% !important;
  height: calc(100vh - 60px) !important;
  margin-top: 0px !important;
}

.vjs-rewind-control {
  height: 80% !important;
}

.vjs-control-bar {
  bottom: 20px !important;
}

@media screen and (max-width: 768px) {
  .vjs-control-bar {
    display: none !important;
  }

  .vjs_video_3-dimensions {
    width: 100% !important;
    height: 100vh !important;
    margin-top: 0px !important;
  }
}

.vjs-cog-button {
  display: none !important;
}

.vjs-cog-menu-button {
  display: none !important;
}

.vjs-logo {
  display: none !important;
}

@media not all and (hover: hover) {
  .custom-height {
    height: var(--app-height);
  }
}

.vjs-rewind-control {
  display: none !important;
}

.Toastify {
  z-index: 9999999
}

.search-input:focus {
  border: 1px solid #25cc89;
}

@media screen and (max-width: 280px) {
  .hide-5th:nth-child(5) {
    display: none;
  }
}

.shimmer-wrapper-card {
  animation: shimmerCard 7s infinite linear;
  background: linear-gradient(to right, #cdced1 4%, #d7d7da 25%, #cdced1 76%);
}

.shimmer-wrapper-small {
  animation: shimmerCard 50s infinite linear;
  background: linear-gradient(to right, #c6cbd9 4%, #d1d5e0 25%, #c6cbd9 76%);
}

@keyframes shimmerCard {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

.slideUp {
  animation: slideUp 0.5s ease-in;
}

@keyframes slideUp {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
  }
}

.vjs-rewind-control {
  display: none !important;
}

.topnav-search-input {
  border-radius: 5px;
  padding-right: 50px;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: none;
  font-weight: 500;
  color: #000000;
}

.topnav-search-input::placeholder {
  color: #243669;
  font-weight: 500;
}

.input-file {
  border: 2px solid #7F82BA !important;
}

.input-file:hover {
  border: 2px solid #7F82BA !important;
}

.show-card:focus {
  border: 1px solid #e7b10e;
}

.vjs-b-r-b {
  display: none !important;
}

.vjs-b-f-b {
  display: none !important;
}

.schedule-card:focus {
  border: 1px solid initial;
}

.tabs-group {
  border-bottom: 1px solid #686868;
}

.tabs-group:focus {
  border-bottom: 1px solid #686868;
}

.select__control {
  background-color: #eaebef !important;
}

.select__menu {
  background-color: #eaebef !important;
}

input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  position: relative;
  background-color: #fff;
  color: #fff;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #7f82ba;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  transition: all 0.15s ease-out;
}

input[type="radio"]:checked {
  background-color: #1a73e8;
  border: 1px solid #1a73e8;
}

.preview-chat {
  background-color: transparent;
  border: none;
  outline: none;
}

.border-preview {
  border: 1px solid #cccccc;
}

.slideInFromleft {
  animation: slideInFromleft 0.5s ease-out forwards;
}

@keyframes slideInFromleft {
  0% {
    width: 0;
  }

  100% {
    width: 500px;
  }
}

.slideOutFromRight {
  animation: slideOutFromRight 0.5s ease-out forwards;
}

@keyframes slideOutFromRight {
  0% {
    width: 500px;
  }

  100% {
    width: 0;
  }
}

.ant-table-cell::before {
  background-color: transparent !important;
}

input[type="radio"]+label span {
  transition: background .2s,
    transform .2s;
}

input[type="radio"]:checked+label span {
  background-color: #134653;
  box-shadow: 0px 0px 0px 3px #ffffff inset;
}

input[type="radio"]+div span {
  transition: background .2s,
    transform .2s;
}

input[type="radio"]:checked+div span {
  background-color: #134653;
  box-shadow: 0px 0px 0px 3px #ffffff inset;
}

.border-hide {
  border: none !important;
}

.shop-border {
  border: 2px solid #ffffff;
}

.shop-border:focus {
  border: 2px solid #ffffff;
}

.shop-border-active {
  border: 2px solid #134653;
}

.border-side {
  border-left: 1px solid #E5E7EB;
}

.tooltip {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: white;
  padding: 5px;
  border: 1px solid #ccc;
  pointer-events: none;
}

.svgMap-map-image {
  --tw-bg-opacity: 1;
  background-color: white;
}

.svgMap-tooltip {
  z-index: 120 !important;
}

.svgMap-tooltip {
  background: rgba(31, 41, 55, 0.9);
  box-shadow: 0px 4px 8px rgba(24, 33, 45, 0.4);
  border-radius: 11px;
  color: #ffffff !important;
}

.svgMap-tooltip .svgMap-tooltip-content-wrapper {
  color: #ffffff !important;
  font-weight: 600 !important;
}

.svgMap-tooltip .svgMap-tooltip-content .svgMap-tooltip-no-data {
  color: #ffffff !important;
  font-weight: 600 !important;
}

.svgMap-tooltip .svgMap-tooltip-content {
  color: #ffffff !important;
  font-weight: 600 !important;
}

.svgMap-tooltip .svgMap-tooltip-content table td span {
  color: #ffffff;
  font-weight: 600 !important;
}

.svgMap-tooltip-pointer {
  display: none;
}

.svgMap-tooltip .svgMap-tooltip-title {
  padding: 0;
}

.svgMap-tooltip .svgMap-tooltip-content-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.svgMap-tooltip .svgMap-tooltip-content-container .svgMap-tooltip-content {
  font-weight: 500;
}

#__next {
  position: relative;
  z-index: 100;
}

.svgMap-tooltip-content, .svgMap-tooltip-flag-container {
  margin: 0 !important;
}

.svgMap-tooltip-content-container {
  align-items: center !important;
  gap: 10px !important;
}

.svgMap-tooltip-title {
  margin-left: -0.5rem !important;
}

.svgMap-map-controls-wrapper {
  display: none !important;
}

.svgMap-map-image {
  position: relative !important;
}

.svgMap-map-wrapper {
  padding-top: 0 !important;
  height: 100% !important;
}

.svgMap-map-container {
  height: 100% !important;
}

.svgMap-container {
  height: 100% !important;
}

.lightBorder {
  border: 1px solid #E5E7EB;
}

.borderComment {
  border-bottom: 1px solid #E5E7EB;
}

.tdarkBorder {
  border: 2px solid #1f2937;
  color: #1f2937;
}

.tdarkBorderActive {
  border: 2px solid #104553;
  color: #104553;
}

.tdark {
  border: 2px solid #104553;
}

.tdarkLight {
  border: 2px solid transparent;
}

.svgMap-country:hover {
  outline: none !important;
  border: none !important;
  stroke: transparent !important;
}


input:checked~.dot {
  transform: translateX(100%);
  background-color: #ffffff;
}

.shadowCountry {
  box-shadow: rgba(50, 50, 93, 0.1) 0px 2px 2px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.css-13cymwt-control, .css-t3ipsp-control {
  min-height: 50px !important;
  border-color: #7f82ba !important;
  height: 50px !important;
}

.css-13cymwt-control:focus, .css-13cymwt-control:hover, .css-13cymwt-control:active {
  min-height: 50px !important;
  border-color: #7f82ba !important;
  height: 50px !important;
}


:root {
  --tooltip-text-color: white;
  --tooltip-background-color: #383838;
  --tooltip-margin: 40px;
  --tooltip-arrow-size: 6px;
}

/* Wrapping */
.Tooltip-Wrapper {
  /* display: inline-block; */
  position: relative;
}

/* Absolute positioning */
.Tooltip-Tip {
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  color: var(--tooltip-text-color);
  background: var(--tooltip-background-color);
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
}

/* CSS border triangles */
.Tooltip-Tip::before {
  content: " ";
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: var(--tooltip-arrow-size);
  margin-left: calc(var(--tooltip-arrow-size) * -1);
}

/* Absolute positioning */
.Tooltip-Tip.top {
  top: calc(var(--tooltip-margin) * -1);
}

/* CSS border triangles */
.Tooltip-Tip.top::before {
  top: 100%;
  border-top-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.right {
  left: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

/* CSS border triangles */
.Tooltip-Tip.right::before {
  left: calc(var(--tooltip-arrow-size) * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: var(--tooltip-background-color);
}

.borderRightGray {
  border-right: 1px solid #E5E7EB;
}

/* Absolute positioning */
.Tooltip-Tip.bottom {
  bottom: calc(var(--tooltip-margin) * -1.5);
}

/* CSS border triangles */
.Tooltip-Tip.bottom::before {
  bottom: 100%;
  border-bottom-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.left {
  left: auto;
  right: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

/* CSS border triangles */
.Tooltip-Tip.left::before {
  left: auto;
  right: calc(var(--tooltip-arrow-size) * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: var(--tooltip-background-color);
}

.typeBorder {
  border: 2px solid #F1F3F9;
}

.linkBorder {
  border: 2px solid #F1F3F9;
}

.btnBorder {
  border: 2px solid rgb(31, 41, 55);
}

.activeBorder, .activeBorder:focus, .activeBorder:hover, .activeBorder:active, .activeBorder:focus-within, .activeBorder:focus-visible {
  border-bottom: 2px solid #104553 !important;
}


.inactiveBorder, .inactiveBorder:focus, .inactiveBorder:hover, .inactiveBorder:active, .inactiveBorder:focus-within, .inactiveBorder:focus-visible {
  border-bottom: 2px solid transparent;
}

.selectedVideo, .selectedVideo:focus, .selectedVideo:hover, .selectedVideo:active, .selectedVideo:focus-within, .selectedVideo:focus-visible {
  border: 2px solid #104553;
}

.borderBottomTabs {
  border-bottom: 1px solid #E5E7EB;
}

.vidCard, .vidCard:focus, .vidCard:hover, .vidCard:active, .vidCard:focus-within, .vidCard:focus-visible {
  border: 2px solid rgb(212,212,212);
}


.shim-blue {
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 155, 255, 0.7);
}

.shim-blue::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg,
      rgba(233, 233, 233, 1) 0,
      rgba(233, 233, 233, 0.9) 50%,
      rgba(233, 233, 233, 0.8) 100%);
  animation: shimmer 2.5s ease-out infinite;
  content: "";
}

@keyframes shimmer {
  100% {
    transform: translateX(0%);
    opacity: 0;
  }
}

.border-plan {
  border: 1px solid #7f82ba;
}

.border-bottom-gray {
  border-bottom: 1px solid #E5E7EB;
}

.usage {
  font-weight: 700;
}